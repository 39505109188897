import React from 'react'
import { graphql } from 'gatsby'
import Image from '../components/Image'
import Layout from '../components/Layout'
import SuperheroBox from '../components/SuperheroBox'
import PulloutBox from '../components/PulloutBox'

export default function About({ data }) {
  const seo = {
    title: 'About',
    slug: 'about',
    image: data.image,
    description: 'Perfecting the unfinished since 1999.',
  }

  return (
    <Layout colorScheme="blanc" layout="basic" seo={seo}>
      <article>
        <SuperheroBox>
          <Image data={data.image} />
        </SuperheroBox>
        <PulloutBox>
          <h2>Perfecting the unfinished since 1999</h2>
        </PulloutBox>
        <p>
          Unsworn is an interaction design and innovation studio based in Malmö,
          Sweden.
        </p>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    image: file(relativePath: { eq: "unsworn/orgsmobile/om_hand_16x9.jpg" }) {
      ...superheroImage
    }
  }
`
